import $ from "jquery";

jQuery(document).ready(function () {
  document.querySelectorAll(".share__btn").forEach(function (btn) {
    btn.addEventListener("click", function (e) {
      e.preventDefault();
      window.open(
        this.href,
        "sharewindow",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=600"
      );
    });
  });

  $(".social__share").on("click", function () {
    $(".share__content").toggle();
  });

  $(document).click(function (event) {
    var target = $(event.target);
    if (!target.closest(".social__share").length) {
      $(".share__content").hide();
    }
  });
});
