import anime from "animejs/lib/anime.es.js";
import { vh } from "./functions";

// ====================== MENU
// ============================================================================ COMPORTEMENT AU CLIC SUR LE BOUTON

jQuery(function ($) {
  $(document).on("click", "#menu__btn", function () {
    $(".header__menu").toggleClass("header__menu--active");
    $(this).toggleClass("menu__btn--active");
    $("body").toggleClass("body--active");
    $(".header").toggleClass("header--fixed");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".header__menu")) {
      $(".header__menu").removeClass("header__menu--active");
      $("#menu__btn").removeClass("menu__btn--active");
      $("body").removeClass("body--active");
      $(".header").removeClass("header--fixed");
    }
  });

  $(".nav-item").on("click", function () {
    $(".header__menu").removeClass("header__menu--active");
    $("body").removeClass("body--active");
    $("#menu__btn").removeClass("menu__btn--active");
    $(".header").removeClass("header--fixed");
  });

  // ============================================================================ ANIMATION DES LIENS DE MENU
  const menuItems = document.querySelectorAll(".menu__main a");

  document.querySelector("#menu__btn").addEventListener("click", function () {
    anime
      .timeline({
        targets: menuItems,
        easing: "easeOutQuint",
      })
      .add({
        opacity: [0, 1],
        translateY: [-30, 0],
        delay: (el, i) => i * 140,
      });
  });

  // ====================== EO MENU
});
