import Swiper from "swiper/bundle";
import Plyr from "plyr";
import "swiper/swiper.scss";
import Rellax from "rellax";
// import "swiper/css/bundle";

jQuery(document).ready(function ($) {
  const player = new Plyr("#player", {
    autoplay: true,
    mute: true,
    loop: { active: true },
    controls: [],
  });

  const swiper = new Swiper(".swiper__slide", {
    slidesPerView: 1,
    // slidesPerGroup: 4,
    spaceBetween: 4,
    loop: true,
    // freeMode: true,
    speed: 900,
    loopedSlides: 10,
    centeredSlides: true,
    initialSlide: 1,
    autoplay: false,
    // allowTouchMove: false,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      360: {
        slidesPerView: 1,
      },
      550: {
        slidesPerView: 1,
      },
    },
  });

  var rellax3 = new Rellax(".rellax-projet", {
    speed: 4,
    vertical: true,
    center: true,
    // wrapper: ".home__projet",
    // relativeToWrapper: true,
  });

  // $(".swiper__realisations .swiper").hover(
  //   function () {
  //     swiper.autoplay.stop();
  //   },
  //   function () {
  //     swiper.autoplay.start();
  //   }
  // );
});
