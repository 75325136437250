// import { hello } from "./functions";
import anime from "animejs/lib/anime.es.js";
import $ from "jquery";
// import "jquery.marquee";

jQuery(document).ready(function () {
  let $icons = $(".header__icons").find(".icon");

  $icons.each(function (i, e) {
    $(e).on("click", function () {
      $(this).toggleClass("active");
    });
  });
});
